@import '../../scss/variables';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-family-sans;
  font-size: $base-font-size;
  line-height: 1.5;
  color: $color-text;
}
