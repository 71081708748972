/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */

/* HSL */
$color-white: hsl(150, 14%, 84%); //light-gray
$color-gray: hsl(81, 28%, 90%); // platinum
$color-yellow: hsl(44, 88%, 66%); // stil de grain yellow
$color-black: hsl(60, 1%, 14%); // raisin black
$color-gray-dark: hsl(120, 2%, 20%); // jet
$color-red: hsl(343, 98%, 39%); //red
$color-blue-dark: hsl(216, 52%, 15%);
$color-green: hsl(92, 33%, 56%); //asparagus green

/* Space colors for backgrounds */
$color-space-1: hsl(341, 43%, 47%); //medium ruby red
$color-space-2: $color-green;
$color-space-3: hsl(196, 31%, 33%); //deep-space-sparkel-green
$color-space-4: $color-yellow;
$color-space-5: hsl(201, 51%, 69%); // dark sky blue
