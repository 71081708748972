.clickable {
  cursor: pointer;
}

a {
  color: $color-link;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 0 $color-link;
  &:hover {
    box-shadow: inset 0 -2px 0 0 $color-link;
  }
}
