@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat'),
    url('./assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');
}

// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 700;
//   src: local("Montserrat"),
//     url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
// }

// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 900;
//   src: local("Montserrat"),
//     url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
// }

@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bungee'), local('Bungee'),
    url('./assets/fonts/Bungee/Bungee-Regular.woff2') format('woff2');
}
