/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */
/* HSL */
/* Space colors for backgrounds */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat"), url(/static/media/Montserrat-Regular.bc3aa95d.woff2) format("woff2"); }

@font-face {
  font-family: 'Bungee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Bungee"), local("Bungee"), url(/static/media/Bungee-Regular.a733a9e3.woff2) format("woff2"); }

/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */
/* HSL */
/* Space colors for backgrounds */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #e8edde; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bungee", cursive;
  color: #f5cc5c;
  margin-bottom: 20px; }

h1 {
  font-size: 32px; }
  @media screen and (min-width: 320px) {
    h1 {
      font-size: calc( 32px + 24 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    h1 {
      font-size: 56px; } }

h2 {
  font-size: 26px; }
  @media screen and (min-width: 320px) {
    h2 {
      font-size: calc( 26px + 10 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 36px; } }

h3 {
  font-size: 22px; }
  @media screen and (min-width: 320px) {
    h3 {
      font-size: calc( 22px + 6 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    h3 {
      font-size: 28px; } }

h4 {
  font-size: 18px; }
  @media screen and (min-width: 320px) {
    h4 {
      font-size: calc( 18px + 0 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    h4 {
      font-size: 18px; } }

.text-title {
  font-family: "Bungee", cursive;
  color: #f5cc5c;
  margin-bottom: 20px; }

.text-large {
  font-size: 22px; }
  @media screen and (min-width: 320px) {
    .text-large {
      font-size: calc( 22px + 6 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    .text-large {
      font-size: 28px; } }

.text-medium {
  font-size: 22px; }
  @media screen and (min-width: 320px) {
    .text-medium {
      font-size: calc( 22px + 6 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 1200px) {
    .text-medium {
      font-size: 28px; } }

/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */
/* HSL */
/* Space colors for backgrounds */
body {
  background-color: #242423; }
  body * {
    box-sizing: border-box; }

#root {
  overflow: hidden;
  width: 100vw;
  height: 100vh; }

.clickable {
  cursor: pointer; }

a {
  color: #f5cc5c;
  text-decoration: none;
  box-shadow: inset 0 -1px 0 0 #f5cc5c; }
  a:hover {
    box-shadow: inset 0 -2px 0 0 #f5cc5c; }

/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */
/* HSL */
/* Space colors for backgrounds */
.app_content__3rbEv {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

/* Coolors Exported Palette - coolors.co/cfdbd5-e8eddf-f5cb5c-242423-333533 */
/* HSL */
/* Space colors for backgrounds */
.loader_loader__3kFg5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #e8edde; }

